import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/projects";

const App = () => {
  return (
    <Routes>
      <Route path="projects/:id/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/projects/84455/carecert" replace />} />
    </Routes>
  );
};

export default App;
