import React from "react";
import Card from "components/card";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

type RowObj = {
  name: string;
  category: string;
  status: boolean;
  description: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const results = Object.keys(tableData).filter(v => v !== 'name' && v !== 'version' && v !== 'commit-sha');
  const categories = Object.keys(tableData).filter(v => v !== 'name' && v !== 'version' && v !== 'commit-sha').map(v => tableData[v]['category'])
  const statuses = Object.keys(tableData).filter(v => v !== 'name' && v !== 'version' && v !== 'commit-sha').map(v => tableData[v]['passed'])
  const descriptions = Object.keys(tableData).filter(v => v !== 'name' && v !== 'version' && v !== 'commit-sha').map(v => tableData[v]['rule_description'])

  const checks = results.map((v, i) => ({name: v, category: categories[i], status: statuses[i], description: descriptions[i]}));

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600">NAME</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <p className="text-sm font-bold text-gray-600">DESCRIPTION</p>
      ),
      cell: (info) => (
        <p className="text-sm text-navy-700">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("category", {
      id: "category",
      header: () => (
        <p className="text-sm font-bold text-gray-600">CATEGORY</p>
      ),
      cell: (info) => (
        <p className="text-sm text-navy-700">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600">STATUS</p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue() === true ? (
            <>
              <MdCheckCircle className="text-green-500 me-1" />
              <p className="text-sm font-bold text-navy-700">Pass</p>
            </>
          ) :  (
            <>
              <MdOutlineError className="text-red-500 me-1" />
              <p className="text-sm font-bold text-navy-700">Fail</p>
            </>
          )}
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  const [data] = React.useState(() => checks);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      {/* <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Front End Care Cert Check Details
        </div>
        <CardMenu />
      </div> */}
      <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
