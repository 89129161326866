// @ts-nocheck
import React from 'react';
import { MdOutlineCheck, MdNumbers, MdOutlineClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import Widget from "components/widget/Widget";
import ComplexTable from "views/carecert/components/ComplexTable";
import axios from 'axios';

const CareCert = () => {
  let { id } = useParams();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const baseUrl = `${process.env.REACT_APP_BX_METRICS_HOST}`;
  
  React.useEffect(() => {
    axios.get(`${baseUrl}/${id}?metric_check=fe_care_cert`, {
      headers: {'Authorization': `Token ${process.env.REACT_APP_BX_METRICS_TOKEN}`}
    }).then(response => {
      setData(response?.data?.data?.metric_data?.checks)
    }).catch(err => {
      setError(err?.response?.data?.message)
    });
  }, [baseUrl])

  if (!data) return <div className='pt-8 pl-2'>⚠️ {error}</div>;

  const totalChecks = Object.keys(data).length - 3;
  const statuses = Object.keys(data).map(v => data[v]['passed'])
  const statusCounts: any = {};

  for (const num of statuses) {
    statusCounts[num] = statusCounts[num] ? statusCounts[num] + 1 : 1;
  }

  return (
    <div>
      <div className="my-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdNumbers className="h-7 w-7" />}
          title={"TOTAL"}
          subtitle={totalChecks.toString()}
        />
        <Widget
          icon={<MdOutlineCheck className="h-6 w-6" />}
          title={"PASS"}
          subtitle={statusCounts['true']}
        />
        <Widget
          icon={<MdOutlineClose className="h-7 w-7" />}
          title={"FAIL"}
          subtitle={statusCounts['false']}
        />
      </div>

      <ComplexTable tableData={data} />

    </div>
  );
};

export default CareCert;
