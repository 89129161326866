import React from "react";

import CareCert from "views/carecert";
import CustomCode from "views/customcode";
import PreCert from "views/precert";

// Icon Imports
import {
  MdDashboard,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "FE Care Cert",
    layout: "/projects",
    path: "carecert",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <CareCert />,
  },
  {
    name: "FE Block Custom Code",
    layout: "/projects",
    path: "customcode",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <CustomCode />,
  },
  {
    name: "FE Pre Cert",
    layout: "/projects",
    path: "precert",
    icon: <MdLock className="h-6 w-6" />,
    component: <PreCert />,
    secondary: true,
  },
];
export default routes;
