// @ts-nocheck
import React from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import Card from "components/card";
import Widget from "components/widget/Widget";
import { MdOutlineDataSaverOff, MdOutlineClose, MdOutlineBarChart } from "react-icons/md";

const CustomCode = () => {
  let { id } = useParams();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const baseUrl = `${process.env.REACT_APP_BX_METRICS_HOST}`;
  
  React.useEffect(() => {
    axios.get(`${baseUrl}/${id}?metric_check=fe_block_custom_code_analyser`, {
      headers: {'Authorization': `Token ${process.env.REACT_APP_BX_METRICS_TOKEN}`}
    }).then(response => {
      setData(response?.data?.data?.metric_data);
    }).catch(err => {
      setError(err?.response?.data?.message);
    });
  }, [baseUrl])

  if (!data) return <div className='m-2'>⚠️ {error}</div>;

  return (
    <>
      <div className="my-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdOutlineDataSaverOff className="h-7 w-7" />}
          title={"STATUS"}
          subtitle={data?.status}
        />
        <Widget
          icon={<MdOutlineClose className="h-6 w-6" />}
          title={"FAILED BLOCKS"}
          subtitle={data?.failed_blocks?.length}
        />
        <Widget
          icon={<MdOutlineBarChart className="h-7 w-7" />}
          title={"ERROR THRESHOLD"}
          subtitle={data?.error_threshold.toString()}
        />
      </div>
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                >
                  <div className="items-center justify-between text-sm font-bold text-gray-600">
                    FAILED BLOCKS
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.failed_blocks.map((value) => {
                return (
                  <tr>
                    <td
                      className="min-w-[150px] border-white/0 py-3  pr-4"
                    >
                      {value}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </>

  );
};

export default CustomCode;
