import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BsArrowDownShort } from "react-icons/bs";

import results_care_cert from '../../mock/results_care_cert.json';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  
  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[470px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 md:w-[480px] md:flex-grow-0 md:gap-1 xl:w-[480px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 xl:w-[225px]">
          <Dropdown
            button={
              <p className="cursor-pointer flex inline">
                <p className="h-7 w-48 px-3 py-2 text-xs text-gray-700 inline"> 
                  {results_care_cert.name}
                </p>
                <BsArrowDownShort className="h-8 inline" /> 
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Project Name
                  </p>
                </div>
                <button className="flex w-full items-center">
                  <div className="flex h-full w-full flex-col justify-center rounded-lg text-sm">
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      Project 1
                    </p>
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      Project 2
                    </p>
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      Project 3
                    </p>
                  </div>
                </button>
              </div>
            }
            classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
          />
        </div>
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <Dropdown
            button={
              <p className="cursor-pointer flex inline">
                <p className="h-7 w-48 px-3 py-2 text-xs text-gray-700 inline"> 
                  {results_care_cert.version}
                </p>
                <BsArrowDownShort className="h-8 inline" /> 
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Branch Name
                  </p>
                </div>
                <button className="flex w-full items-center">
                  <div className="flex h-full w-full flex-col justify-center rounded-lg text-sm">
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      MR name 1
                    </p>
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      MR name 2
                    </p>
                    <p className="mb-1 text-left text-base font-light text-gray-900 dark:text-white">
                      MR name 3
                    </p>
                  </div>
                </button>
              </div>
            }
            classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
          />
        </div>
        {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
